import request from "graphql-request";
import { GetStaticPaths, GetStaticPropsContext } from "next";
import Head from "next/head";

import { urls } from "@shared/constants";
import { docTitle } from "@shared/utils";

import { IndexCallPage, TIndexCallPage } from "__generated__/types";

import ContentBlocks from "src/components/ContentBlocks";

import * as constants from "src/constants";
import bundleMessages from "src/utils/bundleMessages";



type Props = {
  data: TIndexCallPage;
};

export default function CallPage({ data }: Props) {
  return (
    <>
      <Head>
        <title key="title">{docTitle(data.callPage?.call.title.en)}</title>
        <meta
          property="og:title"
          content={docTitle(data.callPage?.call.title.en)}
          key="og-title"
        />
        <meta
          property="og:url"
          content={urls.callPage(data.callPage?.slug ?? "")}
          key="og-url"
        />
      </Head>
      <div className="flex flex-col items-center mx-auto py-10 lg:py-14">
        <ContentBlocks contentBlocks={data.callPage?.about} />
      </div>
    </>
  );
}

export const getStaticPaths: GetStaticPaths<{ id: string }> = async () => {
  return {
    paths: [],
    fallback: "blocking",
  };
};

export async function getStaticProps({
  locale,
  params,
}: GetStaticPropsContext) {
  const messages = await bundleMessages(locale, [
    `${constants.MESSAGE_PREFIX}pages.CallPage`,
  ]);

  const slug: string = params?.slug as string;

  const data: TIndexCallPage | null = await request(
    `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
    IndexCallPage,
    { slug },
  );

  if (!data?.callPage) {
    return {
      notFound: true,
      revalidate: 60,
    };
  }
  return {
    props: {
      data,
      messages,
    },
    revalidate: 60,
  };
}
