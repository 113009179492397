import classNames from "clsx";

import { GalleryBlock, ImageBlock, TextBlock } from "@shared/components";
import { TContentBlock } from "@shared/types";

type Props = {
  contentBlocks: TContentBlock[];
  containerClasses?: string;
};

export default function ContentBlocks({
  contentBlocks,
  containerClasses,
}: Props) {
  return (
    <div
      className={classNames(
        "flex flex-col items-center gap-y-8 lg:gap-y-14",
        containerClasses,
      )}
    >
      {contentBlocks.map((item: TContentBlock, index: number) => {
        if (item.type === "text")
          return (
            <TextBlock
              content={item.content}
              key={`${item.type}${index}`}
              containerClasses="md:w-[700px] max-w-screen-md mx-4"
            />
          );
        if (item.type === "image")
          return (
            <ImageBlock
              imageWidth={700}
              imageHeight={700}
              src={item.content.src}
              caption={item.content.caption}
              description={item.content.description}
              key={`${item.type}${index}`}
              containerClasses="justify-between"
            />
          );
        if (item.type === "gallery")
          return (
            <GalleryBlock
              title={item.content.title}
              description={item.content.description}
              files={item.content.files}
              key={`${item.type}${index}`}
            />
          );
        return null;
      })}
    </div>
  );
}
